import { useEffect, useState } from "react";
import api from "../../services/api";
import rules from "../../rules";

const Can = ({ role, perform, data, yes, no }) => {
  const [permissions, setPermissions] = useState(rules[role]);

  useEffect(() => {
    let isMounted = true;
  
    const fetchPermissions = async () => {
      try {
        const response = await api.get("/permission");
        if (isMounted) {
          setPermissions(response.data);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
  
    fetchPermissions();
  
    return () => {
      isMounted = false;
    };
  }, []);

  const check = (role, action, data, permissions) => {
    const rolePermissions = permissions[role];
    if (!rolePermissions) {
      // role is not present in the permissions
      return false;
    }

    const staticPermissions = rolePermissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      return true;
    }

    return false;
  };

  const canPerformAction = () => {
    if (!permissions) {
      // Permissions are still being fetched
      return false;
    }

    return check(role, perform, data, permissions);
  };

  return canPerformAction() ? yes() : no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export { Can };
