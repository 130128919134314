import { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import openSocket from "../../services/socket-io";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Button, Paper } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import PersonAdd from "@material-ui/icons/PersonAdd";
import PermissionModal from "../../components/PermissionModal";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Skeleton from "@material-ui/lab/Skeleton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },

  description: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  listing: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  iconButton: {
    padding: theme.spacing(1),
  },
}));

const Permissions = () => {
  const classes = useStyles();
  const [addGroupModalOpen, setGroupModalOpen] = useState(false);
  const [selectedGroupPermissionId, setSelectedGroupPermissionId] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [deletingGroup, setDeletingGroup] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpenAddGroupModal = () => {
    setGroupModalOpen(true);
  };

  const handleCloseGroupModal = () => {
    setGroupModalOpen(false);
    setSelectedGroupPermissionId(null);
  };

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/permission/group");
          setGroups(data);

          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("groupPermission", (data) => {
      if (data.action === "create") {
        setGroups([...groups, data.group]);
      } else if (data.action === "update") {
        setGroups(
          groups.map((group) => {
            if (group.id === data.group.id) {
              return data.group;
            }
            return group;
          })
        );
      } else if (data.action === "delete") {
        setGroups(groups.filter((group) => group.id !== data.group.id));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [groups]);

  const handleOpenEditGroupModal = (group) => {
    setSelectedGroupPermissionId(group.id);
    setGroupModalOpen(true);
  };

  const handleDeleteGroup = async (id) => {
    try {
      await api.delete(`/permission/group/delete/${id}`);
      toast.success(i18n.t("permissions.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <PermissionModal
        open={addGroupModalOpen}
        onClose={handleCloseGroupModal}
        aria-labelledby="form-dialog-title"
        groupPermissionId={selectedGroupPermissionId}
      />
      <ConfirmationModal
        title={
          deletingGroup
            ? `${i18n.t("permissions.confirmationModal.deleteTitle")} ${
                deletingGroup.name
              }?`
            : ""
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) => handleDeleteGroup(deletingGroup.id)}
      >
        {i18n.t("permissions.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("permissions.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenAddGroupModal}
            startIcon={<PersonAdd />}
          >
            {i18n.t("permissions.buttons.addGroup")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Grid container className={classes.root} spacing={4}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={4}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      className={classes.title}
                      color="textPrimary"
                      variant="h6"
                    >
                      Permissions ACL Explanation
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.description}
                      color="textSecondary"
                    >
                      On this page you can create, edit and delete ACL groups.
                      You can also assign permissions to groups. Permissions are
                      divided into static and dynamic. Static permissions are
                      predefined. Dynamic permissions are user defined. You can
                      also assign permissions to resources. Resources are
                      predefined. After saving, you can assign permissions to
                      users.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.listing}
                      color="textSecondary"
                    >
                      List of resources:
                      <ul>
                        <li>
                          Drawer Admin Items View
                          <br />- Meaning that you can see the list of
                          connection and admin area.
                        </li>
                        <li>
                          User Modal Edit Profile
                          <br />- Meaning that you can edit your user profile.
                        </li>
                        <li>
                          User Modal Edit Email
                          <br />- Meaning that you can edit your user email.
                        </li>
                        <li>
                          User Modal Edit Queues
                          <br />- Meaning that you can edit your user queues.
                        </li>
                        <li>
                          User Modal Edit Whatsapp
                          <br />- Meaning that you can edit your user default
                          whatsapp.
                        </li>
                        <li>
                          User Modal Edit Group Permission
                          <br />- Meaning that you can edit group permissions.
                        </li>
                        <li>
                          Tickets Manager Show All
                          <br />- Meaning that you can see all tickets if use
                          role is admin.
                        </li>
                        <li>
                          Ticket Options Delete Ticket
                          <br />- Meaning that you can delete a ticket.
                        </li>
                        <li>
                          Ticket Options Transfer Ticket
                          <br />- Meaning that you can transfer access to
                          transfer function.
                        </li>
                        <li>
                          Ticket Options Transfer Whatsapp
                          <br />- Meaning that you can transfer a ticket to
                          another whatsapp.
                        </li>
                        <li>
                          Tickets Accept Button
                          <br />- Meaning that you can accept a ticket.
                        </li>
                        <li>
                          Tickets Return Button
                          <br />- Meaning that you can return a ticket to queues
                          list.
                        </li>
                        <li>
                          Tickets Resolve Button
                          <br />- Meaning that you can resolve a ticket.
                        </li>
                        <li>
                          Contacts Page Delete Contact
                          <br />- Meaning that you can delete a contact.
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper className={classes.control} elevation={0}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} spacing={2}>
                  {!loading ? (
                    <>
                      {groups && groups.length > 0 ? (
                        groups.map((group) => (
                          <Accordion key={group.id}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-label="Expand"
                              aria-controls="panel1a-content"
                              id={`panel1a-header-${group.id}`}
                            >
                              <FormControlLabel
                                aria-label={group.name}
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                  <div style={{ marginRight: "1rem" }}>
                                    <IconButton
                                      aria-label="Edit"
                                      className={classes.iconButton}
                                      onClick={() =>
                                        handleOpenEditGroupModal(group)
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="Delete"
                                      className={classes.iconButton}
                                      color="warning"
                                      onClick={() => {
                                        setDeletingGroup(group);
                                        setConfirmOpen(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                }
                                label={group.name}
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <ul>
                                  {group.resources &&
                                    group.resources.map((resource) => (
                                      <li key={resource.id}>{resource.name}</li>
                                    ))}
                                </ul>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))
                      ) : (
                        <>
                          <Typography
                            className={classes.title}
                            color="textPrimary"
                            variant="h6"
                          >
                            No groups found
                          </Typography>
                          <Typography
                            className={classes.description}
                            color="textSecondary"
                          >
                            Please click the add button to create a new group.
                          </Typography>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Skeleton animation="wave" height={50} width="100%" />
                      <Skeleton animation="wave" height={80} width="100%" />
                      <Skeleton animation="wave" height={50} width="100%" />
                      <Skeleton animation="wave" height={80} width="100%" />
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
};

export default Permissions;
